@import '@/styles/config/';

.section {
  position: relative;
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: $color-bg-sphere-init;
  height: 120rem;

  @include smartphone-down {
    padding: 0 $container-pad;
    height: 90rem;
  }
}
