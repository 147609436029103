@import '@/styles/config/';

.section {
  position: relative;
  padding: 6rem 15rem 32rem;
  background-color: $color-black;

  @include tablet-down {
    padding: 0px 5rem 250px;
  }

  @include smartphone-down {
    padding: 0px 0rem 250px;
  }
}

.inner {
  display: flex;
  justify-content: space-between;
  z-index: 1;
  position: relative;

  @include smartphone-down {
    flex-direction: column;
    align-items: center;
  }
}
