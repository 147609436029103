@import '@/styles/config/';

.heading {
  text-align: center;
  position: relative;
}

.isLeft {
  text-align: left;

  @include smartphone-down {
    margin-left: 0 !important;
  }
}

.text {
  display: block;
  margin-bottom: 1.2rem;
  text-shadow: 0px 0px 24px rgba(255, 255, 255, 0.1);
}

.texth2 {
  margin-bottom: 0.4rem;
  display: block;
  text-shadow: 0px 0px 24px rgba(255, 255, 255, 0.1);

  @include smartphone-down {
    margin-bottom: 12px;
  }
}

.textFooter {
  @include smartphone-down {
    margin-bottom: 12px;
  }
}

.description {
  font-weight: 300;
  margin-top: 2.4rem;
}

.gradient {
  background: linear-gradient(270deg, #8a8c93 50%, rgba(138, 140, 147, 0.6) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba(0, 0, 0, 0);
  display: inline-block;
}
