@import '@/styles/config/';

.modal {
  background-color: $color-white;
  border-radius: 6px;
  box-shadow: $bow-shadow;
  left: 50%;
  padding: 1.2rem 2rem;
  position: absolute;
  top: 50%;
  z-index: $z-modal;
  opacity: 0;
  transform: translate(-50%, -50%) translateY(20px);
  transition: transform 0s $easeOutExpo 0.5s, opacity 0.2s linear, z-index 0.8s linear;
  // pointer-events: none;

  &.show {
    opacity: 1;
    transition: transform 1.6s $easeOutExpo, opacity 0.8s linear;
    transform: translate(-50%, -50%);
    z-index: $z-modal-show;
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  letter-spacing: 0;
  font-weight: 600;

  @include smartphone-down {
    width: 37vw;
  }
}

.icon {
  height: 1.8rem;
  width: 1.8rem;
  path {
    fill: #6f6f72 !important;
  }
}
