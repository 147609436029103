@import '@/styles/config';

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $z-modal;
  background-color: $color-dark-modal;
  border-radius: 8px;
  opacity: 0;
  pointer-events: none;
  transform: scale(0.9);
  transition: $transition-appear-scale;
  z-index: $z-header + 4;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.show {
    pointer-events: auto;
    opacity: 1;
    transform: none;
  }

  .top {
    height: 50%;
    max-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    .topText {
      color: $color-white;
      font-weight: 500;
    }

    .replay {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }

  .bottom {
    flex: 1;
    background-color: #1a1b1f;
    display: flex;
    justify-content: center;
    position: relative;
    border-radius: 0 0 12px 12px;

    .bottom_container {
      margin: auto auto;
    }

    @include smartphone-down {
      padding: 0 20px;
    }

    &::after {
      content: '';
      top: 0;
      left: 0;
      background: #040407;
      box-shadow: 0px 0.5px 0px #545454;
      height: 1px;
      position: absolute;
      width: 100%;
    }
  }
}
