@import '@/styles/config/';

.header {
  position: fixed;
  top: 0;
  width: 100%;

  z-index: $z-header;
  background-color: rgb(18 18 18 / 0%);
  border-bottom: 1px solid #63636300;
  transform: translateY(-100%);
  opacity: 0;
  transition: transform 1.6s $easeOutExpo 0.8s, opacity 0.8s linear, background-color 0.2s linear, border 0.2s linear,
    backdrop-filter 0.2s linear;

  &.show {
    opacity: 1;
    transform: none;
  }

  &.background {
    background-color: rgb(18 18 18 / 72%);
    backdrop-filter: saturate(180%) blur(20px);
    border-bottom: 1px solid #6363634d;
  }
}

.inner {
  padding: 1.1rem 0rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    display: flex;
    align-items: center;
    gap: 40px;
    p {
      color: $color-white;
      cursor: pointer;
      &:hover {
        color: $color-grey;
      }
    }
  };
}

.logo {
  display: flex;
  align-items: center;
  cursor: pointer;

  .icon {
    // width: 28px;
    height: 3.1rem;
  }

  .lazy {
    margin-left: 1.2rem;
    font-weight: 600;
    color: $color-white;
  }
}

.description {
  display: block;
}
