// container
$container-w-small: 1064px;
$container-w: 1280px;
$container-w-big: 1600px;
$container-pad: 20px;

// z-indexes
$z-header: 5000;
$z-modal-sup: 1000;
$z-modal: 100;
$z-modal-show: 100000;
$z-layer: 10;

// shadows
// style 1
// $bow-shadow: 0 0 0px 1px rgba(183, 183, 183, 0.17), rgb(0 0 0 / 40%) 0px 5px 18px, rgb(0 0 0 / 54%) 0px 1px 8px,
//   rgb(18 18 18) 0px 2px 1px -1px;
// style 2 - macos like
// $bow-shadow: 0 0 0 0.5px rgb(0 0 0 / 80%), 0 4px 40px 8px rgb(0 0 0 / 40%), inset 0 0.5px 0 hsl(0deg 0% 100% / 30%);
// style 3 - macos like
$bow-shadow: 0px -2px 6px rgba(255, 255, 255, 0.17), 0 5px 18px rgb(0 0 0 / 40%), 0 4px 40px 8px rgb(0 0 0 / 40%),
  #0000004d 0 1px 4px -1px, inset 0 0 0 0.3px hsl(0deg 0% 100% / 30%), 0 0 0 0.5px hsl(240deg 24% 100% / 40%);
// $bow-shadow: inset 0 0.2px 0 hsl(0deg 0% 100% / 30%),0.7), 0 0.2px 0 hsl(0deg 0% 100% / 30%),0.7), #0000004d 2px 5px 19px 7px;

// transitions
// easing
$easeInExpo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeInOutExpo: cubic-bezier(1, 0, 0, 1);
$easeInQuad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeInOutQuad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$easeInQuart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeInOutQuart: cubic-bezier(0.77, 0, 0.175, 1);
$easeInQuint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
$easeInOutQuint: cubic-bezier(0.86, 0, 0.07, 1);
$easeOutBounce: cubic-bezier(0.22, 1.45, 0.36, 1);

// transitions
$transition-appear-scale: transform 1.2s $easeOutExpo, opacity 0.2s linear;
$transition-opacity: 0.2s linear;

// Media queries
$mobile: 768;
$tablet: 1024;

:export {
  mobile: $mobile;
  tablet: $tablet;
  zIndexModalSup: $z-modal-sup;
}
