@import '@/styles/config/';

.container {
  margin: 0 auto;
  margin-top: 14.8rem;
  display: inline-flex;
  justify-content: center;
  position: relative;
  z-index: $z-layer;
  // padding: 0 12px;
  width: 42.3rem;
  max-width: 75vw;

  @include tablet-down {
    margin-top: 7.8rem;
  }

  @include smartphone-down {
    margin-top: 10rem;
  }
}

.innerCircle {
  width: 50%;
  height: 59%;
  @extend %absolute-center;
}

.logoContainer {
  @extend %absolute-center;
  display: inline-flex;

  transform: translate(-50%, -50%) scale(0);

  &.active {
    transition: transform 1.6s $easeOutBounce;
    transform: translate(-50%, -50%) scale(1);
  }

  &::before {
    content: '';
    @extend %absolute-center;
    width: 50%;
    height: 43%;
    box-shadow: 0 6px 21px 4px rgba(255, 255, 255, 0.83);
  }
}

.logo {
  width: 7.2rem;
  position: relative;
  z-index: $z-layer;
}

.item {
  @extend %absolute-center;
  width: 5.6rem;
  transform: translate(-50%, -50%) scale(0);
  z-index: $z-layer;
  cursor: pointer;

  &.active {
    transition: transform 2s $easeOutBounce;
    transform: translate(-50%, -50%) scale(1);
  }

  &:hover {
    transition: transform 1.2s $easeOutBounce;
    transform: translate(-50%, -50%) scale(1.1);
  }
}

.pointLight {
  @extend %absolute-center;
  width: 8px;
  height: 8px;
  background: $color-white;
  box-shadow: 0px 0px 8.06984px $color-white;
  border-radius: 50%;
  top: 0;
  left: 0;
}
