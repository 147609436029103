@import '@/styles/config/';

.containerAppear {
  position: relative;
  z-index: $z-modal-sup;
}

.container {
  // colors from Lazy App
  border-radius: 16px;

  margin: 0 auto;
  max-width: 117.1rem;
  padding: 4.8rem 5.6rem;
  position: relative;
  transform: translateY(50%);
  width: 100%;
  z-index: $z-modal-sup;

  &::before {
    opacity: 0.4;
    box-shadow: 0px 0px 29.6876px -7.42191px rgba(131, 132, 153, 0.2),
      inset 0px 0px 0.775073px rgba(255, 255, 255, 0.15);
    filter: drop-shadow(0px 0px 5.56643px rgba(255, 255, 255, 0.15));
    border-radius: 18px;
    border: 1px solid transparent;
    background: linear-gradient(138.16deg, #161617 -14.83%, #141416 92.59%) padding-box,
      linear-gradient(132.32deg, rgba(255, 255, 255, 0.3) 26.17%, rgba(255, 255, 255, 0.385) 127.6%) border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    content: '';
  }

  &::after {
    background: linear-gradient(86.26deg, rgba(28, 29, 33, 0.98) 61.1%, rgba(28, 29, 33, 0.784) 96.93%);
    backdrop-filter: blur(2px);
    border: 1px solid #26272c;
    border-radius: inherit;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    content: '';
    z-index: $z-modal-sup - 1;
  }

  @include smartphone-down {
    padding: 20px 20px;

    .texts {
      margin-bottom: 20px;
    }
  }
}

.inner {
  position: relative;
  z-index: $z-modal-sup;
  display: flex;
  justify-content: space-between;

  @include smartphone-down {
    flex-direction: column;
  }
}

.glow {
  // background: linear-gradient(180.06deg, #c4c4c4 0.06%, rgba(196, 196, 196, 0) 92.12%);
  opacity: 0.3;
  // filter: blur(100px);
  box-shadow: 0 -23px 40px -5px rgb(196 196 196 / 36%);
  border-radius: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

.text {
  margin-bottom: 1.2rem;
}
