@import '@/styles/config/';

.banner {
  opacity: 0; // Hide the header for now
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 2.4rem;
  padding-top: 22rem;
  @include smartphone-down {
    padding-top: 18rem;
  }

  a {
    cursor: pointer;
    transition: color 0.2s linear;
  }
}

.gradientWrapper {
  width: 24.4rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
}

.textWrapper {
  display: flex;
  justify-content: center;
  position: relative;
  padding: 0 1rem;

  .lineLeft {
    position: absolute;
    top: 50%;
    height: 2px;
    left: 0.5rem;
    width: 4.5rem;
    transform: translate(-100%, -50%);
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.2021183473) 0%,
      rgb(255 255 255 / 44%) 66%,
      rgba(0, 0, 0, 0.2021183473) 100%
    );
  }

  .lineRight {
    position: absolute;
    top: 50%;
    height: 2px;
    right: 0.5rem;
    width: 3rem;
    transform: translate(100%, -50%);
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.2021183473) 0%,
      rgb(255 255 255 / 88%) 90%,
      rgba(0, 0, 0, 0.2021183473) 100%
    );
  }

  .text {
    color: $color-grey;
    &:hover {
      color: $color-white;
    }
  }

  .subtext {
    color: $color-white;
    &:hover {
      color: $color-grey;
    }
  }

  .separatorWrapper {
    width: 0.4rem;
    margin: 0 1rem;
  }
}
