@import '@/styles/config/';

.section {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 18.4rem 0;
  background: linear-gradient(180.05deg, #131416 34.23%, #0a0a0b 71.07%);

  @include smartphone-down {
    padding: 60px 0;
  }
}

.image {
  max-width: 100rem;
  margin-top: 6rem;

  @include smartphone-down {
    margin-top: 40px;
  }
}
