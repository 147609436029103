@import '@/styles/config/';

.paragraph {
  margin: 4.4rem auto 0rem;
  width: 35rem;
  text-align: center;
  padding-bottom: 24.4rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) !important;
  bottom: 170px;
  @include smartphone-down {
    visibility: hidden;
    bottom: inherit;
  }
}

.link {
  display: inline;
  color: $color-white;
}
