%border-gradient {
  border-radius: 4px;
  border: 0.5px solid transparent;
  background: linear-gradient(138.16deg, #313135 -14.83%, #1f2024 92.59%) padding-box,
    linear-gradient(94.85deg, #8c8c93 0.63%, #3f3f43 100%) border-box;
}

%border-gradient-hover {
  transition: border 0.2s linear, background 0.5s ease;
  &:hover {
    background: linear-gradient(rgb(78, 79, 82), rgb(78, 79, 82)) padding-box padding-box,
      linear-gradient(rgb(184, 184, 184) -76.62%, rgba(184, 184, 184, 0) 131.05%) border-box border-box;
    border: $color-white solid 0.5px;
  }
}

%absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
