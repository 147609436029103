@import '@/styles/config/';

.container {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(25, 26, 27, 0.91);
  backdrop-filter: blur(7px);
  width: 100%;
  height: 100%;
  z-index: $z-header;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: $transition-opacity;

  &.show {
    opacity: 1;
    pointer-events: auto;

    .content {
      transition: transform 0.8s $easeOutBounce, opacity 0.2s linear;
      transform: scale(1) translateZ(0);
      opacity: 1;
    }
  }
}

.content {
  // background-color: $color-dark-grey;
  transform: scale(0.9) translateZ(0);
  opacity: 0;

  padding: 5.3rem 10.8rem 6.6rem;
  text-align: center;
  position: relative;
  z-index: $z-header + 1;
  max-width: 85vw;

  @include smartphone-down {
    width: 100%;
    padding: 40px 20px 40px;
  }

  &::after {
    border: 0.5px solid transparent;
    background: linear-gradient(138.16deg, rgba(19, 20, 22, 0.9) 100%, rgba(19, 20, 22, 1) 100%) padding-box,
      linear-gradient(139.75deg, rgb(69, 72, 79, 1), #232328 70%) border-box;
    backdrop-filter: blur(13.9781px);
    box-shadow: 0px 0px 5.4687px rgba(255, 255, 255, 0.03), 0px 0px 29.1664px -7.2916px rgba(131, 132, 153, 0.04),
      inset 0px 0px 0.761465px rgba(255, 255, 255, 0.03);
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    content: '';
    z-index: $z-header - 1;
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% + 16px);
    height: calc(100% + 16px);
    transform: translate(-50%, -50%);
    border-radius: 14px;
    content: '';
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1px);
    box-shadow: 0px 0px 5.4687px rgba(255, 255, 255, 0.03), 0px 0px 29.1664px -7.2916px rgba(131, 132, 153, 0.04),
      inset 0px 0px 0.761465px rgba(255, 255, 255, 0.03);
    border: 0.5px solid #54545459;
    // opacity: 0.2;
    z-index: $z-header - 2;
  }

  .closeBox {
    position: absolute;
    background: #1f2024;
    border: 0.5px solid #545454;
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 8px;
    z-index: $z-header;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 14px;
    right: 14px;
    cursor: pointer;

    .closeIcon {
      transition: transform 1.2s $easeOutExpo;
    }

    &:hover {
      background-color: rgba(26, 27, 31, 1);

      .closeIcon {
        transform: rotate(90deg);
      }
    }
  }
}

.inner {
  width: 30.8rem;
  margin: 0 auto;
  position: relative;
  z-index: $z-header;
}

.logo {
  width: 100%;
  position: relative;
  z-index: $z-layer;
}

.logoContainer {
  margin: 0 auto;
  margin-bottom: 1.7rem;
  position: relative;
  display: inline-flex;

  &::before {
    content: '';
    @extend %absolute-center;
    width: 47%;
    height: 39%;
    transform: translate(-50%, -50%);
    box-shadow: 0 5px 23px 2px rgb(255 255 255 / 83%);
  }
}

.title {
  color: $color-white;
  margin-bottom: 2.4rem;
}

.text {
  margin-bottom: 2.4rem;
}
