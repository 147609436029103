@import '@/styles/config/';

.canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  canvas {
    width: 100% !important;
    height: 100% !important;
  }
}
