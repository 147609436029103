@import '@/styles/config/';

.section {
  // padding-top: 48.3rem;
  // padding-bottom: 48.3rem;
  color: $color-black;
  height: 90rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: none;
  position: relative;

  &:hover {
    cursor: none;
  }

  @include smartphone-down {
    height: 400px;
  }
}
