.container {
  padding-top: 70px;
  height: 8000px;
}

.inner {
  position: sticky;
  top: 70px;
  height: calc(100vh - 70px * 2);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
