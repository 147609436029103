@import '@/styles/config/';

.section {
  position: relative;
  padding: 12rem 0px;
  border: 1px solid #26272c;
  border-radius: 12px;

  @include smartphone-down {
    padding: 40px 0;
  }
}
