@import '@/styles/config/';

.footer {
  background-color: $color-black;
  position: relative;
  padding-bottom: 6rem;
  color: #62646a;
}

.top {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  padding: 2.4rem 0;
  align-items: center;

  .logo {
    display: flex;
    align-items: center;
    cursor: pointer;
    flex: 1 0;

    .icon {
      // width: 28px;
      height: 3.1rem;
    }

    .lazy {
      margin-left: 1.2rem;
      font-weight: 600;
      color: $color-white;
    }
  }
  .center {
    flex: 1 0;
    text-align: center;
    font-size: 12px;
  }

  .socials {
    flex: 1 0;
    text-align: right;

    .social {
      opacity: 0.4;
      transition: opacity 0.2s linear;
      margin-left: 1.2rem;

      &:hover {
        opacity: 1;
      }

      svg {
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }
}

.bottom {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 0;
  font-size: 11px;

  .terms {
    flex: 1 0 0;

    text-align: right;

    a {
      cursor: pointer;
      transition: color 0.2s linear;
      &:hover {
        color: $color-white;
      }
    }
  }

  .copyrights {
    flex: 1 0 0;
    text-align: left;
  }
}
