@import '@/styles/config/';

.launcher {
  position: relative;
  width: 68rem;
  margin: 8.4rem auto 4.4rem;
  box-shadow: $bow-shadow;
  cursor: pointer;
  background: linear-gradient(177.75deg, rgba(26, 27, 30, 0.9) 1.89%, rgba(12, 13, 17, 0.5) 98.11%);
  z-index: $z-header + 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 16px;
  border-radius: 8px;
  overflow: hidden;
  // filter: drop-shadow(0px -1px 2px rgba(255, 255, 255, 0.07));

  @include smartphone-down {
    display: none;
  }
}

.launcher_img {
  display: none;

  @include smartphone-down {
    // max-width: 90vw;
    margin-top: 50px;
    box-shadow: none;
    background: none;
    display: block;
    img {
      width: 100%;
    }

    @keyframes blinker {
      50% {
        opacity: 0;
      }
    }

    &::after {
      content: '';
      width: 1px;
      height: 2vw;
      background-color: white;
      display: block;
      box-shadow: white 0px 0px 10px;
      position: absolute;
      left: 8.6%;
      top: 23.5%;
      animation: 1s linear 0s infinite normal none running blinker;
    }
  }
}

.bottom {
  // backdrop-filter: blur(6px);
  z-index: $z-header + 1;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-top: 8px;
  width: 100%;
  color: $color-white;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 8px;
  position: relative;
  z-index: 1;

  .divider {
    position: absolute;
    width: calc(100% + 32px);
    left: -16px;
    right: -15px;
    bottom: 65.5px;
    z-index: -1;
    height: 0.5px;
  }

  &::after {
    content: '';
    position: absolute;
    background-color: rgba(26, 27, 31, 0.98);
    height: 75px;
    bottom: -8px;
    left: -16px;
    right: -16px;
    z-index: -3;
  }

  @include smartphone-down {
    height: 70px;
  }

  path {
    stroke: $color-white;
  }

  .captureIconWrapper {
    display: flex;
    align-items: center;
    flex: 1 0 0;
    margin-bottom: 0.6rem;

    .captureIcon {
      width: 2.2rem;
      height: 2.2rem;
      margin-right: 0.6rem;
    }
  }

  .context {
    // width: 2.8rem;
    height: 2.8rem;
    // flex: 1 0 0;
    margin-bottom: 0.2rem;
  }

  .buttonWrapper {
    flex: 1 0 0;
    text-align: right;
    & > div {
      width: 73px;
      margin-left: calc(100% - 73px);

      .button {
        display: inline-block;
        padding: 0.6rem 1.4rem;
        @extend %border-gradient;
        transition: border 0.5s ease-out;

        &.enable {
          cursor: pointer;
          @extend %border-gradient-hover;
          transition: border 0.5s ease-out, background 0.5s ease-out;
          border: $color-white solid 0.8px;
        }
      }
    }
  }
}

.inner {
  width: 100%;
  min-height: 13.5rem;
  z-index: $z-header + 1;
  @extend %border-gradient;
  border-radius: 8px;
  box-shadow: rgb(4 4 7 / 45%) 0px 2px 2px, rgb(4 4 7 / 60%) 0px 8px 24px;
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  border-width: 0.5px;

  & > div:nth-of-type(1) {
    // #launcher_editor
    position: relative;
    flex: 1;
    // & > div {
    // & > div:first-of-type {
    //   //.ProseMirror
    //   color: $color-white;
    //   padding: 1.6rem 1.2rem 0 2.4rem;
    //   width: 100%;
    //   height: 100%;
    //   background: transparent;
    //   border: none;
    //   overflow: auto;
    //   outline: none;
    //   font-size: 1.6rem;
    //   -webkit-box-shadow: none;
    //   -moz-box-shadow: none;
    //   box-shadow: none;
    // }
    // }
  }

  @include smartphone-down {
    height: 90px;
  }

  .link {
    background: rgba(33, 34, 38, 0.8);
    opacity: 0.7;
    border: 0.5px solid #4b4a51;
    border-radius: 100px;
    display: flex;
    padding: 0.3rem 1rem;
    color: $color-white;
    width: fit-content;
    margin: 0.6rem 1.2rem 1.2rem 1.4rem;

    .linkIcon {
      width: 1.6rem;
      height: 1.6rem;
      margin-right: 0.6rem;
    }
  }
}

.editor {
  max-height: 100px;
  min-height: 83px;
  width: 100%;
  overflow: auto;
  kbd {
    font-size: 0.75em;
  }

  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    font: revert;
  }
}

.placeholder {
  gap: 4px;
  min-width: 500px;
  display: flex;
  align-items: center;
  color: #4e4f52;
  & > span {
    vertical-align: text-top;
    & > span {
      height: 1em;
      width: 1em;
      min-width: 20px;
      min-height: 20px;
    }
  }
}
