@import '@/styles/config/';

.button {
  --x-px: calc(var(--x) * 1px);
  --y-px: calc(var(--y) * 1px);
  --border: 0px;
  --radial-radius: 25rem;

  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  position: relative;
  padding: 1px;
  // overflow: hidden;
  // background-color: #414141;
  // @extend %border-gradient;
  // @extend %border-gradient-hover;

  // box-shadow: rgb(0 0 0 / 12%) 0px 12px 12px;
  background: linear-gradient(115.52deg, rgba(255, 255, 255, 0.17) 17.85%, rgba(255, 255, 255, 0) 110.5%);

  &:hover {
    &::before {
      opacity: 1;
    }
    .gradient {
      opacity: 1;
    }

    .content {
      background-color: $color-button-bg;
      box-shadow: rgb(0 0 0 / 12%) 0px 7px 7px, 0px -2px 7px rgba(255, 255, 255, 0.07);

      transition: box-shadow 400ms ease 0s;
    }
    .text {
      color: white;
      transition: color 1500ms ease 0s;
    }
    .icon {
      opacity: 100%;
      transition: opacity 1500ms ease 0s;
      // transition: color 0.2s linear;
      color: $color-white;
    }
  }

  &::before {
    content: '';
    pointer-events: none;
    user-select: none;
    position: absolute;
    top: 0;
    left: 0;
    inset: 0px;
    border-radius: inherit;
    opacity: 1;
    transition: opacity 400ms ease 0s;
    will-change: background, opacity;
    background: radial-gradient(
      var(--radial-radius) circle at var(--x) var(--y),
      rgba(255, 255, 255, 0.3),
      transparent 30%
    );
  }

  &.modalFull {
    .inner {
      background: $color-dark-modal;
      padding: 7px 12px;
      .text {
        color: $color-white;
      }
    }

    &:hover {
      .inner {
        background: $color-inside-sphere;
      }
    }
  }

  .gradient {
    pointer-events: none;
    user-select: none;
    position: absolute;
    z-index: 1;
    opacity: 1;
    top: var(--border);
    bottom: var(--border);
    left: var(--border);
    right: var(--border);
    border-radius: inherit;
    will-change: background, opacity;
    background: radial-gradient(
      var(--radial-radius) circle at var(--x) var(--y),
      rgba(255, 255, 255, 0.05),
      transparent
    );
    contain: strict;
    transition: opacity 400ms ease 0s;
  }

  .content {
    padding: 6px 12px;
    display: flex;
    border-radius: 4px;
    align-items: center;
    // z-index: $z;
    width: 100%;
    text-align: center;
    justify-content: center;

    border-radius: 3.5px;
    background: rgba(26, 27, 31, 0.9);
    position: relative;
    transition: background 0.3s linear;
  }

  .text {
    // margin-right: 1rem;
    color: #b5b6ba;
    letter-spacing: 0;
  }

  .icon {
    opacity: 40%;
    width: 16px;
    height: 16px;
    margin-left: 1rem;
  }
}
/* ∑ */
