@import '@/styles/config/';

.section {
  position: relative;
  background-color: $color-black;
  padding-top: 14.7rem;

  @include smartphone-down {
    padding-top: 20px;
  }
}

.inner {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 106rem;
  width: 100%;
  overflow: hidden;
  border-radius: 0px 0px 48px 48px;
  background: linear-gradient(180deg, $color-grey-800 0%, #1a1b1f 100%);

  // background: linear-gradient(180deg, $color-dark-grey 0%, rgb(29, 29, 29) 100%);
}

.bkg {
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 100%;
}
