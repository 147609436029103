@import '@/styles/config/';

.container {
  position: relative;
  height: 40rem;

  @include smartphone-down {
    height: 300px;
  }
}

.bkg {
  position: absolute;
  top: 56%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;

  @include tablet-down {
    width: 100%;
    height: auto;
  }
}

.inner {
  position: relative;
  height: 40rem;

  @include smartphone-down {
    height: 300px;
  }
}

.card {
  padding: 3.2rem;
  position: absolute;

  background: #1a1b1f;
  /* shadow/mid-hover */

  box-shadow: 0px 2px 4px rgba(4, 4, 7, 0.25), 0px 8px 24px rgba(4, 4, 7, 0.4);
  // border: 1px solid #7c7c7c;
  width: 100%;
  max-width: 63.7rem;
  width: 63.7rem;

  display: flex;
  top: 50%;
  left: 50%;
  z-index: 8;
  transform: translate(-50%, -50%) translateY(18%) scale(0.9);
  opacity: 0.6;
  transition: transform 1s $easeOutQuart, opacity 1s linear;

  border: 0.5px solid transparent;
  border-radius: 8px;
  background: linear-gradient(138.16deg, #1a1b1f -14.83%, #1a1b1f 92.59%) padding-box,
    linear-gradient(133.42deg, #6c6d77 -135.52%, #1a1b1f 135.21%) border-box;

  @include tablet-down {
    max-width: 90vw;
    padding: 20px;
  }

  &.first {
    transition: transform 1s $easeOutQuart 0.5s, opacity 1s linear;
    transform: translate(-50%, -50%);
    z-index: 10;
    opacity: 1;
  }

  &.second {
    transition: transform 1s $easeOutQuart 0.6s, opacity 1s linear;
    transform: translate(-50%, -50%) translateY(9%) scale(0.95);
    z-index: 9;
    opacity: 0.8;
  }

  &.isMoving {
    transform: translate(-50%, -50%) translateY(-15%) scale(1);
    transition: transform 1s $easeOutQuart 0s, opacity 0.4s linear 0.2s;
    opacity: 0;
    z-index: 15;
  }
}

.profil {
  width: 4.8rem;
  // height: 4.8rem;
}

.content {
  padding-left: 3.8rem;

  @include smartphone-down {
    padding-left: 20px;
  }
}

.username {
  color: #b8b8b8;
  margin-right: 5px;
  display: inline-block;
}

.titles {
  margin-bottom: 0.6rem;
}

.tag {
  color: #4e4f52;
}

.text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  /* or 157% */

  letter-spacing: -0.05px;

  /* Text/Primary */

  color: #e0e0e0;

  @include smartphone-down {
    line-height: 1.4em;
  }

  span {
    opacity: 0.5;
  }
}
