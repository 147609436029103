@import '@/styles/config/';

.NavTitle {
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 2.4rem;
  margin-top: 2.4rem;
  @include smartphone-down {
    margin-top: 0rem;
  }
  // padding-top: 22rem;
}

.gradientWrapper {
  width: 24.4rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
}

.textWrapper {
  display: flex;
  justify-content: center;
  position: relative;
  padding: 0 1rem;

  .text {
    color: $color-grey-tertiary;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    @include smartphone-down {
      font-size: 10px;
    }
  }

  .subtext {
    color: $color-white;
  }

  .separatorWrapper {
    width: 0.4rem;
    margin: 0 1rem;
  }
}
