@import '@/styles/config/';

.section {
  position: relative;
  padding-top: 27.3rem;

  @include smartphone-down {
    padding-top: 0;
  }
}
