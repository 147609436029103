@import '@/styles/config/';

.container {
  max-width: calc($container-w + $container-pad * 2);
  width: 100%;
  margin: 0 auto;
  padding: 0 $container-pad;
  position: relative;
}

.big {
  max-width: calc($container-w-big + $container-pad * 2);
}

.small {
  max-width: calc($container-w-small + $container-pad * 2);
}
