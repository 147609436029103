@import '@/styles/config/';

.section {
  // height: 100vh;
  padding-top: 25.5rem;
  padding-bottom: 24rem;
  background-color: $color-black;
  text-align: center;
  position: relative;
  height: 2800px;

  @include tablet-down {
    height: 2400px;
  }

  @include smartphone-down {
    height: 2200px;
  }
}

.inner {
  position: sticky;
  top: 0px;
  height: calc(100vh - 70px * 2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: $z-modal;
}

.content {
  height: 100%;
}
