@mixin w-420-down {
  @media (max-width: 420px) {
    @content;
  }
}

@mixin smartphone-down {
  @media (max-width: #{$mobile}px) {
    @content;
  }
}

@mixin tablet-down {
  @media (max-width: #{$tablet}px) {
    @content;
  }
}
