$color-white: white;
$color-black-full: black;
$color-black: #0a0a0a;
$color-grey: #8a8c93;
$color-grey-tertiary: #62646a;
$color-grey-800: #131416;
$color-dark-grey: #101012;
$color-button-hover: #26272c;
$color-dark-modal: #212226; // color of launcher without gradient
$color-bg-sphere: #1c1d21;
$color-inside-sphere: #121214;
$color-button-bg: rgb(20, 21, 23);
$color-bg-sphere-init: #27282d;
// 1d1d1d

:export {
  black: $color-black;
  darkGrey: $color-dark-grey;
  grey: $color-grey;
  bgSphere: $color-bg-sphere;
  bgSphereInit: $color-bg-sphere-init;
  insideSphere: $color-inside-sphere;
  grey800: $color-grey-800;
}
